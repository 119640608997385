.textarea {
  div {
    height: auto !important;
  }
  textarea {
    height: 200px !important;
    align-items: flex-start;
    border: theme('colors.gray.200') 1px solid;
  }
}
