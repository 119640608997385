.ButtonBack {
  z-index: 11;
  width: 100%;
  animation: enterIn 0.3s ease 0.3s 1 both;
}

.ButtonBackSmall {
  z-index: 11;
  width: 100%;
  animation: enterOut 0.3s ease 0.3s 1 both;
}

@keyframes enterIn {
  0% {
    transform: translateX(300px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes enterOut {
  0% {
    transform: translateX(-300px);
  }
  100% {
    transform: translateX(0);
  }
}

