.FavoritesAddButton {
  position: relative;
  outline: 0 !important;
  z-index: 10;
  &:before,
  &:after {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    content: '';
  }
  &:before {
    box-sizing: border-box;
    margin: -1.5rem;
    border: solid 2.25rem #e2264d;
    width: 3rem;
    height: 3rem;
    transform: scale(0);
  }
}
.focused {
  animation: heart 1.5s cubic-bezier(0.17, 0.89, 0.32, 1.49);
  &:before,
  &:after {
    animation: heart 1.5s cubic-bezier(0.17, 0.89, 0.32, 1.49);
    animation-timing-function: cubic-bezier(0.21, 0.61, 0.35, 1);
  }
  &:before {
    will-change: transform, border-width, border-color;
    animation-name: bubble;
  }
  $bubble-d: 2rem; // bubble diameter
  $bubble-r: 0.5 * $bubble-d; // bubble-radius
  @mixin bubble($ext) {
    transform: scale(1);
    border-color: theme('colors.primary');
    border-width: $ext;
  }
  @keyframes heart {
    0%,
    17.5% {
      font-size: 0;
    }
  }

  @keyframes bubble {
    15% {
      @include bubble($bubble-r);
    }
    30%,
    100% {
      @include bubble(0);
    }
  }
}
