.ReactModal__Overlay {
    opacity: 0;
    transition: all 200ms;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.ReactModal__Content {
    @media only screen and (max-width: 768px) {
        opacity: 0;
        transition: all 600ms;
    }
}

.ReactModal__Content--after-open {
    @media only screen and (max-width: 768px) {
        transform: translateY(0vh);
        opacity: 1;
    }
}

.ReactModal__Content--before-close {
    @media only screen and (max-width: 768px) {
        transform: translateY(100vh);
        opacity: 0;
    }
}