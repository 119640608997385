:global {
  .react-datepicker {
    display: flex;
    border: none !important;
  }
  .react-datepicker__time-container,
  .react-datepicker__time-box {
    width: 100% !important;
  }
  .react-datepicker__header {
    background-color: transparent !important;
    border: none !important;
  }
  .react-datepicker__current-month {
    font-weight: 400;
    font-size: 16px;
  }
  .react-datepicker__day-name {
    font-weight: bold;
  }
  .react-datepicker__day-names {
    border-bottom: 1px solid theme('colors.gray.200') !important;
    border-top: 1px solid theme('colors.gray.200') !important;
    padding: 10px 0;
    margin-top: 12px;
  }
  .react-datepicker__navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    top: 12.5px;
    width: 20px !important;
    height: 20px !important;
    background-size: 100%;
    outline: 0 !important;
    &--previous {
      background-image: url(../../../assets/icons/icon-calendar-left.svg);
    }
    &--next {
      background-image: url(../../../assets/icons/icon-calendar-right.svg);
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover,
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background-color: theme('colors.primary') !important;
    border: 0 !important;
    outline: 0 !important;
    color: white !important;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2.1rem !important;
    line-height: 2.1rem !important;
    min-width: 2.1rem !important;
    min-height: 2.1rem !important;
  }
  .react-datepicker__day--today {
    position: relative;
    &:after {
      content: '';
      display: flex;
      height: 2px;
      width: 100%;
      border-radius: 3px;
      background-color: theme('colors.primary');
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &:hover {
      &:after {
        display: none;
      }
    }
  }
  .react-datepicker__day--selected:after{
    display: none;
  }
  .react-datepicker__time-container {
    display: flex;
    flex-direction: row;
    border: 0;
    float: 0;
    align-items: center;
    justify-content: space-between;
    width: 70% !important;
    margin: auto;
    border-left: 1px solid #E8ECF3 !important;
    .react-datepicker__time-list {
      height: auto;
      width: 100%;
      &::-webkit-scrollbar {
        width: 3px;
        height: 5px;
        /* background-color: #AEAEAE */
      }
    }
    .react-datepicker__header--time {
      display: none;
    }
    .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--selected {
      background-color: theme('colors.primary') !important;
      border: 0 !important;
      outline: 0 !important;
      color: white !important;
    }
  }
}
