.fullWidthHr {
  width: calc(100% + 40px) !important;
}

.customInput {
  flex-direction: row !important;
  align-items: flex-end !important;
  margin-bottom: 0 !important;
  & > label {
    font-weight: 600;
    font-size: 1rem !important;
    white-space: nowrap;
    width: 220px;
  }
  & > div {
    align-items: flex-end;
  }
  & > div > input {
    font-size: 1rem !important;
    font-weight: 600;
    height: 45px !important;
  }
}

.editButton {
  background-color: #0395ff !important;
  color: #fff !important;
  padding: 15px 35px !important;
  border-radius: 10px !important;
}

.boxButton {
  background-color: #fff !important;
  border-radius: 10px !important;
}

.form {
  input {
    border: none !important;
  }
}
