* {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  min-height: 100vh;
  padding: 0;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  font-family: var(--secondary);
  background: white;
  color: #2d3133;
}

a {
  cursor: pointer;
}

*::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.9)
*/
  background: transparent;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  /* background-color: #AEAEAE */
}

*::-webkit-scrollbar-thumb {
  background-color: #0395ff !important;
  border-radius: 10px !important;
  border-radius: 10px !important;
}

.react-tabs__tab--selected {
  color: theme('colors.primary') !important;
}

.react-tabs li:after,
div[data-tabs='true'] li:after {
  content: '';
  border-bottom: 1px solid theme('colors.gray.300');
  display: block;
  width: 100%;
  margin-bottom: -1px;
  margin-top: 10px;
}

.react-tabs .react-tabs__tab--selected:after,
div[data-tabs='true'] .react-tabs__tab--selected:after {
  content: '';
  border-bottom: 1px solid theme('colors.primary');
  display: block;
  width: 100%;
}

//kristian media mixin
// default breakpknts
$xs: '480px';
$sm: '600px';
$md: '960px';
$lg: '1280px';
$xl: '1920px';

@mixin media-min($size) {
  @media (min-width: $size) {
    @content;
  }
}
@mixin media-max($size) {
  @media (max-width: $size) {
    @content;
  }
}

.MuiTypography-root,
.MuiButton-root,
.MuiInputBase-input,
.MuiSelect-root {
  font-family: 'Noto Sans HK', sans-serif !important;
}
.MuiTab-root,
.MuiTab-wrapper {
  align-items: flex-start !important;
}
.MuiBox-root-952 {
  padding: 0px !important;
}
.MuiTableRow-root {
  border: 2px solid #f5f5f5 !important;
}
.MuiTableCell-head {
  color: #666666 !important;
}
.MuiCheckbox-root {
  color: black !important;
}
.MuiAccordionSummary-root {
  padding: 0px 40px !important;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.MuiAccordionSummary-content {
  margin: 0px !important;
  margin-bottom: 4px !important;
}
.MuiAccordionSummary-root {
  min-height: 58px !important;
}
.MuiAccordionDetails-root {
  padding-bottom: 0px !important;
}
.MuiAccordion-root.Mui-expanded:before {
  opacity: 1 !important;
}
.MuiAccordion-root.Mui-expanded {
  margin: 0px !important;
}
.MuiPaper-elevation8 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  margin-top: 40px;
  li.Mui-disabled {
    font-style: italic;
  }
  @include media-min($md) {
    max-height: 300px;
    border-radius: 6px;
  }
  .MuiList-padding {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.project__popup .MuiDialog-paper {
  @include media-min($md) {
    width: 960px !important;
  }
  .MuiGrid-root {
    width: 100%;
  }
}

.MuiGrid-grid-xs-6 .react-swipeable-view-container {
  //targeting only tabs inside single pages, giving them min screen height because of the backgrounds
  min-height: 100vh;
  div[data-swipeable='true'],
  div[data-swipeable='false'] {
    overflow-x: hidden !important;
  }
}

.deals {
  &__table {
  }
  &__table > div > .MuiGrid-container > div:first-child .deals__th {
    border-top-left-radius: 10px;
  }
  &__table > div > .MuiGrid-container > div:last-child .deals__th {
    border-top-right-radius: 10px;
  }
}

.MuiDialog-paperFullScreen.MuiDialog-paper {
  form {
    .MuiInputBase-input {
      display: flex;
      align-items: center;
      font-size: 0.875rem !important;
      padding: 0px 20px;
      border-radius: 4px;
      background-color: white;
      min-height: 50px;
    }
    .MuiOutlinedInput-root.Mui-focused {
      input {
        border: none !important;
      }
    }

    .MuiInputBase-formControl fieldset,
    .MuiInputBase-root fieldset {
      border-color: transparent !important;
    }

    .MuiInputBase-formControl.MuiOutlinedInput-root.Mui-focused fieldset,
    .MuiOutlinedInput-root.Mui-focused fieldset {
      border-color: theme('colors.primary') !important;
    }
  }
}
.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding: 0 !important;
}
.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded
  .MuiList-padding {
  .MuiListItem-root.Mui-disabled {
    background-color: theme('colors.secondary');
    color: white;
    font-size: 0.75rem;
  }
  .MuiListItem-button:hover {
    background-color: theme('colors.primary');
    color: white;
  }
}
.employees__form {
  width: 100%;
  form {
    width: 100%;
  }
}

.react-code-input {
  display: flex !important;
}

.MuiTablePagination-root .MuiTablePagination-toolbar {
  & > p:first-of-type {
    display: flex;
    height: 56px;
    align-items: center;
    border: 1px solid #e1e1e1;
    border-right: 0;
    color: #070707;
    font-family: Noto Sans HK;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
    padding: 18px 0px 20px 18px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .MuiTablePagination-input.MuiTablePagination-selectRoot {
    padding: 0;
    width: 110px;
    height: 56px;
    display: flex;
    align-items: center;
    margin: 0;
    border: 1px solid #e1e1e1;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left: 0;

    div {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0;
      text-align: left;
      padding-left: 3px;
      color: #070707;
      font-family: Noto Sans HK;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 140%;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      background-color: white !important;
    }
    .MuiSelect-icon {
      right: 10px;
    }
  }
}

.MuiTableContainer-root .MuiTableRow-head th:last-of-type {
  padding-left: 0 !important;
}

.MuiTableContainer-root .MuiTableBody-root td:last-of-type button {
  border-radius: 6px !important;
  color: #000000;
  font-weight: 500 !important;
}

.MuiTableContainer-root .MuiTableBody-root tr:not(.MuiTableRow-hover) {
  //display: none'' !important;
}

.MuiTableBody-root {
  .MuiTableCell-paddingCheckbox + .MuiTableCell-paddingNone {
    padding-left: 16px !important;
  }
}

.contact__socials {
  button:hover {
    background-color: #dcdcdc !important;
  }
  button {
    box-shadow: none !important;
  }
}

.MuiDialog-scrollPaper {
  align-items: flex-start !important;
}

.offer__details {
  @include media-max($sm) {
    &-block {
      padding: 20px 30px !important;
      max-width: 100vw;
    }
  }
  &-header {
    padding: 30px;
    @include media-min($sm) {
      padding: 30px;
    }
    @include media-max($sm) {
      &__list .MuiGrid-grid-xs-12:not(:first-of-type) {
        margin-bottom: 20px;
      }
    }
    @include media-max($sm) {
      div [align='right'] {
        text-align: left !important;
      }
    }
  }
  &-body {
    margin-top: 30px;
    padding: 15px 0px;
    @include media-min($sm) {
      padding: 30px;
      margin-top: 0;
    }
  }
}

.offers__filters {
  flex-direction: column;
  @include media-min($sm) {
    flex-direction: row;
  }
}

.offers__filters-filter {
  @include media-max($sm) {
    margin-right: 0 !important;
    width: 100% !important;
    margin-left: 0 !important;
    margin-top: 10px !important;
  }
}

.offers__header {
  flex-direction: column;
  @include media-min($sm) {
    flex-direction: row;
  }
}

.offers__buttons {
  justify-content: space-between !important;
  @include media-min($sm) {
    justify-content: flex-end !important;
  }
}

.react-swipeable-view-container {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: auto !important;
}

.MuiDialog-paperFullScreen {
  max-height: 100vh !important;
}

.MuiAutocomplete-hasPopupIcon[role='combobox'] {
  input.MuiInputBase-input {
    order: -1;
    width: 100%;
    display: flex;
    margin-bottom: 10px;
  }
  .MuiAutocomplete-endAdornment {
    top: 13px;
  }
  div.MuiAutocomplete-tag {
    background-color: theme('colors.primary');
    color: white;
    margin-bottom: 10px;
  }
  svg.MuiSvgIcon-root.MuiChip-deleteIcon{
    color: white;
  }
}
.head-title {
  font-size: 19px !important;
}
.searchbar {
  max-width: 250px;
  display: flex;
  margin-bottom: auto;
  margin-top: auto;
  height: 50px;
  border-radius: 20px;
}
.navBarFlex {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fadeInWidth {
  animation: 0.2s linear fadeInWidth;
}

.fadeOutWidth {
  animation: 0.2s linear fadeOutWidth;
}

.fadeIn {
  animation: 0.8s linear fadeIn;
}
.fadeOut {
  animation: 1s linear fadeOut;
}

.search-input {
  color: white;
  border: 0;
  outline: 0;
  background: none;
  padding: 0px 10px;
  transition: width 0.4s linear;
  caret-color: #0395FF;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  border-bottom: 1px solid #2D3133;
}
.search-input:hover {
  border-bottom: 2px solid #0395FF;
}
.search-input:active {
  border-bottom: 2px solid #0395FF;
}

.search-icon {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2D3133;
}

.icon-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
}
.icon-bg:hover {
  opacity: 0.8;
  cursor: pointer;
}

@keyframes fadeInWidth {
  0% {
    width: 30px;
  }
  100% {
    width: 200px;
  }
}
@keyframes fadeOutWidth {
  0% {
    width: 200px;
  }
  100% {
    width: 30px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
