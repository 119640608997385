.search {
  & > div {
    height: initial !important;
  }
  input {
    padding: 10px !important;
    padding-left: 50px !important;
  }
}

.select {
  margin-bottom: 0 !important;
  & > div {
    height: 46px !important;
    min-height: 46px !important;
  }
  & > div {
    border-width: 1px !important;
  }
}
