.ModalWindow {
  @media screen and (min-width: 768px) {
    max-height: calc(100vh - 60px) !important;
  }
  @media screen and (min-width: 768px) and (min-height: 800px) {
    max-height: 850px !important;
  }
  @media screen and (max-width: 768px) {
    height: 88vh;
    max-height: calc(100vh - 40px);
  }
  & > div {
  }
}

:global {
  .modal--login {
    padding-bottom: 20px !important;
    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 24px !important;
      line-height: 36px;
      letter-spacing: 0.4px;
      color: #0395ff !important;
    }

    .modal-close {
      display: none !important;
    }
    .modal-body {
      padding-bottom: 20px !important;
    }
  }
  .modal--password {
    padding-bottom: 20px !important;
    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 24px !important;
      line-height: 36px;
      letter-spacing: 0.4px;
      color: #0395ff !important;
    }

    .modal-body {
      padding-bottom: 20px !important;
    }
  }
  .modal--alert {
    & > div {
      width: 400px !important;
      padding: 30px !important;
      & > div:first-child {
        display: none !important;
      }
      h2 {
        margin-top: 0 !important;
      }
    }
  }
  .modal--audience {
    & > div {
      width: 920px !important;
      max-width: 1200px !important;
      padding: 30px !important;
      h2 {
        margin-top: 0 !important;
      }
      & > div:first-child {
        padding: 0 !important;
        h1 {
          display: none;
        }
        .modal-close {
          margin-left: auto;
        }
      }
    }
  }
  .modal--newTemplate {
    & > div {
      width: 430px !important;
      max-width: 1200px !important;
      padding: 30px !important;
      h2 {
        margin-top: 0 !important;
      }
      & > div:first-child {
        padding: 0 !important;
        h1 {
          display: none;
        }
        .modal-close {
          margin-left: auto;
        }
      }
    }
  }
  .modal--schedule {
    & > div {
      width: 430px !important;
      max-width: 1200px !important;
      padding: 30px !important;
      h2 {
        margin-top: 0 !important;
      }
      & > div:first-child {
        padding: 0 !important;
        h1 {
          display: none;
        }
        .modal-close {
          margin-left: auto;
        }
      }
    }
  }
  .modal--automate {
    & > div {
      width: 800px !important;
      max-width: 1200px !important;
      padding: 40px 30px !important;
      h2 {
        margin-top: 0 !important;
      }
      & > div:first-child {
        padding: 0 !important;
        h1 {
          display: none;
        }
        .modal-close {
          margin-left: auto;
        }
      }
    }
  }
  .modal--mail-builder {
    & > div {
      width: 1200px !important;
      max-width: 1500px !important;
      padding: 40px 30px !important;
      h2 {
        margin-top: 0 !important;
      }
      & > div:first-child {
        padding: 0 !important;
        h1 {
          display: none;
        }
        .modal-close {
          margin-left: auto;
        }
      }
    }
  }
  .modal--notepopup {
    & > div {
      width: 640px !important;
      max-width: 1200px !important;
      padding: 40px 30px !important;
      & > div:first-child {
        padding: 0 !important;
        display: none !important;
      }
    }
  }
  .modal--newChat {
    & > div {
      width: 640px !important;
      max-width: 1200px !important;
      padding: 40px 30px !important;
      & > div:first-child {
        display: none !important;
      }
    }
  }
}
