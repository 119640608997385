.ModalWindow {
  @media screen and (min-width: 768px) {
    max-height: calc(100vh - 60px) !important;
  }
  @media screen and (min-width: 768px) and (min-height: 800px) {
    max-height: 850px !important;
  }
  @media screen and (max-width: 768px) {
    height: 88vh;
    max-height: calc(100vh - 40px);
  }
  & > div {
  }
}
