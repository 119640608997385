.loader {
  display: inline-block;
  position: relative;
  width: 140px;
  height: 140px;
}
.loader div {
  position: absolute;
  border: 10px solid #0395ff;
  opacity: 1;
  border-radius: 50%;
  animation: pulse 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loader div:nth-child(2) {
  animation-delay: -0.5s;
  border-color: #0b2559;
}
@keyframes pulse {
  0% {
    top: 70px;
    left: 70px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 140px;
    height: 140px;
    opacity: 0;
  }
}
