$calendar-border: transparent;
$cell-border: transparent;
$calendar-spacing: 10px;
$out-of-range-bg-color: #e9edf2;
$btn-border: rgb(3, 149, 255);

@import '../../node_modules/react-big-calendar/lib/sass/styles.scss';

.ccal.rbc-calendar {
  .rbc-header {
    overflow: visible;
    padding: 0;

    span {
      background: #ffffff;
      border-radius: 10px;
      padding: $calendar-spacing;
      border: 2px solid rgb(242, 245, 250);

      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .rbc-day-bg {
    position: relative;

    border: 2px solid rgb(242, 245, 250);

    background-color: white;
    border-radius: 10px;

    &.rbc-off-range-bg {
      background-color: #e9edf2;
    }
  }

  .rbc-row {
    padding-top: 4px;
    padding-bottom: 4px;

    &:first-child {
      padding-top: 8x;
    }

    &:last-child {
      padding-bottom: 8px;
    }

    &-segment {
      padding: 0px 10px;
    }
  }

  .rbc-month-row {
    max-height: 155px;

    .rbc-row-segment {
      padding-top: 0;
    }
  }

  .rbc-date-cell {
    padding-right: 15px;
  }

  .rbc-toolbar {
    button {
      padding: 10px 20px;
      margin-right: 10px;
      outline: 2px solid transparent;
      outline-offset: 2px;
      border-radius: 10px;

      &:focus {
        background-color: transparent;
      }

      &:hover,
      &:active {
        background-color: rgb(87, 121, 255);
        color: white;
        border-color: transparent;
        box-shadow: none;
      }
    }

    .rbc-btn-group:last-child {
      display: none;
    }

    .rbc-toolbar-label {
      text-align: right;
      font-weight: 600;
      font-size: 1.75rem;
    }
  }

  .rbc-event {
    min-height: 25px;
    border: 0;
    border-left: 3px solid var(--borderColor);
    border-radius: 2px;
    z-index: 10;
  }

  .rbc-allday-cell {
    .rbc-row:empty {
      display: none;
    }
  }

  .rbc-time-header-cell {
    min-height: 55px;
  }
}

.react-datepicker--time-only .react-datepicker__time-container {
  border: 0 !important;
  justify-content: center;
}
