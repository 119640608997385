@font-face {
    font-family: 'Encode Sans Semi Expanded';
    src: url('../fonts/subset-EncodeSansSemiExpanded-Bold.woff2') format('woff2'),
        url('../fonts/subset-EncodeSansSemiExpanded-Bold.woff') format('woff'),
        url('../fonts/subset-EncodeSansSemiExpanded-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Encode Sans Semi Expanded SmBd';
    src: url('../fonts/subset-EncodeSansSemiExpanded-SemiBold.woff2') format('woff2'),
        url('../fonts/subset-EncodeSansSemiExpanded-SemiBold.woff') format('woff'),
        url('../fonts/subset-EncodeSansSemiExpanded-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('../fonts/subset-NunitoSans-Regular.woff2') format('woff2'),
        url('../fonts/subset-NunitoSans-Regular.woff') format('woff'),
        url('../fonts/subset-NunitoSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Encode Sans Semi Expanded Normal';
    src: url('../fonts/subset-EncodeSansSemiExpanded-Medium.woff2') format('woff2'),
        url('../fonts/subset-EncodeSansSemiExpanded-Medium.woff') format('woff'),
        url('../fonts/subset-EncodeSansSemiExpanded-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

